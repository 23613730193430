.topbar{
  //color:#fff;
  //background-color: #B71C1C;
  //background-color: #B71C1C;
  padding:0;
  line-height: 50px;
  box-shadow: none;
  border-bottom:1px solid #ccc;
}
.btn50top{
  color:#fff;
  background-color: #B71C1C;
  width: 50px;
  text-align:center;
  margin-right: 10px;
  border-bottom:1px solid #ccc;
  height: 50px!important;
}

a{
  color:#B71C1C;
  text-decoration: none;
}
a.mainlink,.mat-accordion .mat-expansion-panel:not(.mat-expanded), .mat-accordion .mat-expansion-panel:not(.mat-expansion-panel-spacing){
  border-radius: 0;
  border-bottom:solid 1px rgba(0, 0, 0, 0.12);
}
a.mainlink,.sidemenu li a{
  color:#000;
}
.sidemenu{
  /*border-left:1px solid;
  border-bottom:1px solid;
  border-color:rgba(0, 0, 0, 0.12);*/
}
.sidemenu li a{
  //border-bottom:solid 1px rgba(0, 0, 0, 0.12);
}
/*
.sidemenu li a:before{
  content: "";
  border-radius: 50%;
  height: 5px;
  width: 5px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
  background-color: #333;
}*/

.barcontainer a.active-link{
  background-color: rgba(0,0,0,.1);
}

.userDetails{
  border-bottom:solid 1px rgba(0, 0, 0, 0.12);
}
.left_bar{
  //background-color: #B71C1C;
  background-color: #B71C1C;
  // background-image: linear-gradient(#B71C1C, #b7c9fb);
}
.default_panel{
  background-color: #fff;
}
.default_heading{
  border-bottom:1px solid rgba(0,0,0,.125);
}
.default_footer{
  border-top:1px solid #eee;
}
.mat-mdc-menu-item.active{
  background-color: #eee;
}
.mat-drawer-content{
  background-color: #eaf0f7;
}
.pagetitle{
  border-top:solid 1px rgba(0, 0, 0, 0.12);
}
.chartpanel{
  background-color: #fff;
}
.datasetpanel{
  background-color: #fff;
}
.isborderleft{
  border-left:3px solid #B71C1C;
}
.bgopacity{
  background-color: rgba(246, 29, 55, 0.6);
}
.borderbottom{
  border-bottom:1px solid #ccc;
}
.smalltext{
  color:#8392a5;
}
.scrolling::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.a  bsoluteLoading{
  background-color: rgba(255,255,255,.5);
}
.settingsBar{
  background-color: #fff;
}
.settingsBackDrop{
  background-color: rgba(0,0,0,0.4);
}
.mat-expansion-panel-header-title .mat-icon{
  color:#666;
}
.searchrow{
  border-bottom:1px solid #ccc;
}
.searchrow,.noresult{
  background-color: #fff;
}
.searchtext{
  color:#B71C1C;
}
.profiletopdetails{
  position:relative;
  padding:20px;
  border:1px solid #ddd;
  /*background-image: url('/../../../../assets/img/themes/beanstalk.png');*/
  background-color: #fff;
}

.counteruser{
  margin-bottom:5px;
  color:#888;
  padding:10px 20px;
  background-image: url('../../assets/img/themes/beanstalk.png');
}
.flightsettings{
  background-color:#eee;
}
.settsubtitle{
  color:#666;
}

.companytop:before{
  content:"";
  position:absolute;
  top:0;
  right:0;
  width:200%;
  height:100%;
  background-color: #DAE4FD;
  transform:rotate(25deg);
  /*border-top:2px solid #B71C1C;*/
}
.autoNotificationBar,.manualNotificationBar{
  background-color: #fff;
}
.BackdropAuto,.BackdropManual{
  background-color: rgba(0,0,0,0.4);
}

.notificationsingle:hover{
  background-color: #eee;
}
.notificationsingle.unread{
  background-color: #e4f4fb;
}
.notificationsingle .notificationIcon{
  margin-top:10px;
  background-color: #fff;
  border-radius: 50%;
  text-align:center;
  height: 35px;
  width: 34px;
  display: inline-block;
  line-height: 34px;
  border:1px solid #eee;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}

.companySubSpan{
  background-color:#B71C1C;
  color:#fff;
}

/** SEARCH FLIGHT FORM MODULE **/

.smallmenu{
  font-size: 12px;
  width: 100%;
  border-bottom: 1px solid #333;
  text-align: left;
  padding:0 5px;
  font-weight: normal;
  border-radius:0;
  height: 56px;
}

.inline-width{
  display:inline-block;
  width:100px;
  margin:0 5px;
}
.lightbg{
  background-color: #fef2ed;
  margin-bottom: 8px;
  border-left:3px solid #B71C1C;
  padding-top:5px;
  padding-left:20px;
  position:relative;
}
.flightnumeration{
  position:absolute;
  left:-13px;
  top:30%;
  width:20px;
  height:20px;
  line-height: 20px;
  background-color: #B71C1C;
  border-radius: 50%;
  text-align:center;
  color:#fff;
}

.singleiata{
  display:inline-block;
  text-transform: uppercase;
  padding:2px 5px;
  font-size:10px;
  font-family: monospace;
  background-color: #fff;
  border:1px solid #ccc;
  margin:0 8px 8px 0;
  position: relative;
  border-radius: 4px;
}

.cancelicon{
  cursor:pointer;
  position: absolute;
  top: -6px;
  right: -8px;
  font-size: 16px;
  display: block;
  width: 14px;
  height: 14px;
  line-height: 14px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
}
.handle-terminal{
  position: absolute;
  top: 6px;
  right: 6px;
  color: #ccc;
  cursor: move;
  width: 18px;
  height: 18px;
}
.fakefield{
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: #f9f9f9;
  border: 1px solid #eee;
}

/** SEARCH RESULTS **/

.price{
  color:#333;
  font-weight: bold;
  font-size:14px;
  display: inline-block;
}

.isRecom{
  background-color: #fff;
  padding:15px;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  font-size:12px;
  border-top: 4px solid #B71C1C;
  position: relative;
}

.issection{
  padding:10px 5px;
  background-color: #fff;
}

.ratesContainer{
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  font-size: 11px;
  padding: 0;
  position: relative;
  margin-bottom: 10px;
}

.ratesSlider{
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: relative;
  padding:5px 10px;
}

.singlefare{
  float:left;
  width: 240px;
  margin:0 20px 0 0;
  padding: 0;
  border-top:3px solid #5c6670;
  box-shadow: 0 4px 4px rgba(0,0,0,.2);
}

.dialogFare{
  margin:0 10px 20px 0;
  padding:10px;
  border-top:3px solid #5c6670;
  box-shadow: 0 4px 4px rgba(0,0,0,.2);
}

.fareName{
  color:#B71C1C;
  font-size: 12px;
  padding:5px 5px 0;
}

.bagInfo{
  padding:2px 5px 10px;
  margin-bottom:5px;
  border-bottom:1px solid #ccc;
}

.bagInfo .material-icons{
  font-size:14px;
  vertical-align: sub;
}

.farePrice{
  font-size:14px;
  //font-family: monospace;
  margin-bottom:5px;
  border-bottom:1px solid #ccc;
  padding:0 5px;
}
.bottomActions{
  padding:2px 5px;
}
.bottomActions .material-icons{
  font-size:14px;
  vertical-align: sub;
  cursor: pointer;
  margin-right: 5px;
}

.nextExist{
  border-bottom:3px solid #ccc;
  padding-bottom: 30px;
  margin-bottom:20px;
}

.selectedRate{
  position: relative;
  border-color:#B71C1C;
}

.selectionFare span{
  margin:0;
  padding:2px 5px;
  cursor: pointer;
  font-size:12px;
  display: inline-block;
}
.selectionFare .material-icons{
  font-size:14px;
  vertical-align: sub;
  height:14px;
  width: 14px;
}

.negotiatedRow{
  background-color: green;
  color:#fff;
  margin: -15px -15px 10px;
  padding:10px 10px;
}

.recoCarrier{
  width:30px;
  border-radius: 50%;
  border:1px solid #ccc;
  display: inline-block;
  margin-right:5px;
}

.progressLoader div{
  background-color: #eee;
  cursor:pointer;
  padding:20px 10px;
}

.marketingcarrierimage{
  width: 30px;
  border-radius: 50%;
  border: 1px solid #ccc;
}

.templateBackDrop{
  position: fixed;
  top:0;
  left:0;
  bottom:0;
  width: 100%;
  background-color: rgba(0,0,0,.5);
  z-index:19999;
}

.templateDialog{
  position:fixed;
  left:0;
  top:0;
  bottom:0;
  width: 100%;
  z-index:20000;
}

.dialogContent{
  max-width:1200px;
  background-color: #fff;
  min-height:100px;
  margin:50px auto 50px;
}

.introItinerary{
  padding-bottom:0;
  border-bottom:1px solid #ccc;
  font-weight: 500;
  margin-bottom:10px;
}
.introItinerary p{
  margin:0;
}
.flightCabin {
  color: rgba(0,0,0,.54);
}

/****/

.flimagecarr{
  max-width:40px;
}

.headingseg{
  font-size:15px;
  font-weight: 400;
  color:#3c4043;
  margin-bottom:15px;
}

.headingdetails{
  color: rgba(0,0,0,.56);
  margin-bottom:5px;
  font-size:14px;
}

.fldetails{
  color: rgba(0,0,0,.56);
  margin-bottom:25px;
}

.initialclass{
  padding-top:20px;
  border-top:1px solid #ccc;
}

.flightdet{
  padding:0 0 0 30px;
  background-image:url('/assets/img/dotted_flight_80dp.png');
  background-position:left 0;
  background-repeat:no-repeat;
}
.scalepause{
  border-top:1px solid #ccc;
  border-bottom:1px solid #ccc;
  padding:15px 0;
  margin:15px 0
}
/******/

.fareNameNew{
  color:#B71C1C;
  font-size: 13px;
  padding:5px 5px 0;
  margin-bottom:5px;
  text-transform:capitalize;
}

.dialogFareNew{
  margin:0 10px 20px 0;
  padding:0;
  border:1px solid #CCC;
  .bagInfo{
    padding:10px 10px 5px 10px;
    border:none;
    .material-icons{
      margin-right:5px;
      color: #B71C1C;
    }
  }
  .bottomActions{
    margin-bottom:10px;
    padding:0 10px 5px 10px;
    border:none;
  }
  .farePrice{
    margin:0 10px 5px;
    border:1px solid #B71C1C;
    text-align:center;
    border-radius:4px;
    cursor:pointer;
  }
}

.dialogFareNew.selectedRate{
  border:3px solid #B71C1C;
  .farePrice{
    margin:0;
    background-color: #B71C1C;
    text-align:center;
    border-radius:0;
    color:#fff;
  }
}
.greytxt{
  color:#999;
}
.greentxt{
  color:green;
}

.steptariffContainer{
  padding:10px;
  background-color: #fff;
  box-shadow:0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);
}
.mainbg{
  background-color: #fff;
}

.nowloading{
  background-color: #fff;
  box-shadow:0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);
}

.countStep{
  background-color: #B71C1C;
  text-align:center;
  color:#fff;
  display:inline-block;
  padding:5px;
  margin-right:5px;
  border-radius: 50%;
  height:20px;
  width:20px;
  line-height: 12px;
  font-size:12px;
  vertical-align: middle;
}

/*** PNR ***/

.cardPnr,.card{
  background-color: #fff;
  border: none;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  border-radius: 0;
}

.reservationimage{
  margin-bottom: 0;
  font-size:12px;
}

.reservationimage img{
  display: block;
  width:25px;
  border-radius:50%;
  border-bottom:2px solid #cccccc;
  margin-bottom:5px;
}

.reservationPrice{
  font-size:16px;
}
.variationpnr{
  color:#ff5722;
  padding:5px 0 0;
}
.variationpnr .mat-icon{
  vertical-align: middle;
  font-size:16px;
  width:20px;
  height:20px;
  line-height: 16px;
}

.pnrstatus{
  font-size:11px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.pnrstatus span{
  border:1px solid;
  padding:0 5px;
}
.pnrstatus span.init{
  border-color: #B71C1C;
  color: #B71C1C;
}
.pnrstatus span.linked{
  border-color: #155724;
  color: #155724;
}
.pnrstatus span.issued,.pnrstatus span.flown{
  border-color: #888;
}
.pnrstatus span.cancelled,.pnrstatus span.expired{
  border-color: #ff5722;
  color: #ff5722;
}

.ssrtimelimit{
  color:#777;
  font-style: italic;
  font-size: 11px;
}

.waitingtimelimit{
  color:#dba300;
}

.timelimit{
  color:#721c24;
}
.timelimit .mat-icon{
  vertical-align: middle;
  font-size:16px;
  width:20px;
  height:20px;
  line-height: 16px;
}
.counterlimit{
  font-size:11px;
}
.pnrlocator{
  display: inline-block;
  margin-left: 10px;
}
.companydetails .mat-icon{
  vertical-align: bottom;
}
.negoicon .mat-icon{
  vertical-align: middle;
  font-size:16px;
  width:20px;
  height:20px;
  line-height: 16px;
}
.priceDetails{
  font-size:12px;
}
.listpnrsinglepasseng{
  font-size:11px;
  text-transform: uppercase;
}

.pnrPagination{
  padding:10px 0;
}

.singleVoice{
  text-align:center;
  display: inline-block;
  padding:10px 15px;
  margin:0 5px 5px 0;
  cursor:pointer;
  vertical-align: middle;
  background-color: #fff;
  box-shadow:0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  font-size: 11px;
}

.singleVoice .mat-icon{
  margin-bottom:5px;
  font-size:18px;
  height:20px;
  width: 20px;
  line-height: 20px;
}

.singleVoice.active{
  color:#B71C1C;
  box-shadow: inset 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.travelinfo{
  font-size:12px;
}

.pnrPagination{
  padding:10px 0;
}

.singleVoice{
  text-align:center;
  display: inline-block;
  padding:10px 15px;
  margin:0 5px 5px 0;
  cursor:pointer;
  vertical-align: middle;
  background-color: #fff;
  box-shadow:0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  font-size: 11px;
}

.singleVoice .mat-icon{
  margin-bottom:5px;
  font-size:18px;
  height:20px;
  width: 20px;
  line-height: 20px;
}

.singleVoice.active{
  color:#B71C1C;
  box-shadow: inset 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.tabname{
  display: block;
}
.standardFields{
  background-color: #eee;
  padding-top: 5px;
  border: 1px solid #ddd;
}

.tabButton{
  border: 1px solid transparent;
  border-right:1px solid #ccc;
}

.activeTab{
  background-color: #B71C1C;
  color:#fff;
  border-radius: 0 5px 0 0;
}

.tabContent{
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.serviceSect{
  display: block;
  padding:2px 4px;
  font-size:11px;
  text-transform: uppercase;
}

.activeBtn{
  background-color: #B71C1C;
  color:#fff!important;
}

.thisTab{
  background-color: #B71C1C;
  color:#fff;
  padding:2px 4px;
}

.mapCompartmentContainer{
  text-align: center;
}

.singleSeatCont{
  display:inline-block;
}

.singleSeat{
  display:inline-block;
  margin:5px;
  width:30px;
  height:30px;
  line-height: 30px;
  border:1px solid #ccc;
  font-size:11px;
  position:relative;
  cursor:pointer;
  .seatmapdesc{
    position:absolute;
    top:30px;
    width:200px;
    left:-80px;
    background-color: #eee;
    border:1px solid #ccc;
    display:none;
    line-height:24px;
    font-size:12px;
    padding:5px 10px;
    text-align:left;
    color:#000;
    div{
      border-bottom:1px solid #ccc
    }
    div:last-child{
      border:none;
    }
  }
}
.singleSeat:hover{
  background-color:#DAE4FD;
  color:#000;
}
.singleSeat:hover > .seatmapdesc{
  display: block;
  z-index:999;
}

.legend{
  background-color: #eee;
}

.not-allowed,.not-allowed:hover{
  background-color: rgba(255, 0, 0,.4);
  color:#fff;
}

.compartMent{
  position: relative;
  &:after{
    position:absolute;
    bottom:-10px;
    left:0;
    right:0;
    content:"";
    border-bottom: 2px solid #B71C1C;
    z-index: 1000;
  }
}

.seatSingle{
  background-color: #eee;
}

.personhere{
  background-color: green;
  color: #fff;
}
.singleRoom{
  padding:10px;
  margin:0 5px;
  border-left: 3px solid #B71C1C;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.singleGuest{
  margin:0 5px;
  padding:5px 10px;
  font-size:12px;
  background-color: #EDF1FE;
}

/***/

.hotelResults{
  padding:0;
}

.introHotel{
  padding:10px;
}

.hotelName{
  color:#B71C1C;
}
.hotelCity{

}
.hotelAddress{
  color:#999;
  font-size:12px;
}

/*****/

.list_table{
  position:relative;
  box-shadow:0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12)
}

.list_grid{
  position:relative;
}

.progressLoader{
  position:absolute;
  top:0;
  left: 0;
  width:100%;
  height:5px;
  z-index:999;
}

.tablelist{
  /*max-height: 600px;*/
  overflow: auto;
}

/* width */
.tablelist::-webkit-scrollbar {
  width: 5px;
  height:5px;
}

/* Track */
.tablelist::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.tablelist::-webkit-scrollbar-thumb {
  background: #B71C1C;
}

/* Handle on hover */
.tablelist::-webkit-scrollbar-thumb:hover {
  background: #B71C1C;
}

.tablelist table{
  width: 100%;
}

.tablelist.withscroll table {
  min-width:900px;
}

.tablelist table span.sorting{
  display: inline-block;
  vertical-align: middle;
  cursor:pointer;
}

.tablelist table tr:hover > td{
  background-color: #eee;
}

.searchrow{
  padding:10px 10px 10px;
  background-color:#fff;
  border-bottom:1px solid #ccc;
  box-shadow:0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12)
}

.listimage{
  width:40px;
  display:inline-block;
  vertical-align:middle;
  margin-right:5px;
}

.capitalize{
  text-transform: lowercase;
}

/*********/

.user-card{
  /*max-width:400px;*/
}

.user-header-image {
  background-image: url('https://material.angular.io/assets/img/examples/shiba1.jpg');
  background-size: cover;
}

.user-card .mat-mdc-card-header .mat-mdc-card-title{
  font-size:14px;
}
.user-card .mat-mdc-card-subtitle{
  font-size:12px;
}

.user-card .mat-icon{
  height: 18px;
  width: 18px;
  font-size:18px;
  vertical-align:middle;
}

.paginationwidth{
  width:50px;
  display:inline-block;
  margin-right:10px;
}

td.mat-mdc-cell, td.mat-mdc-footer-cell, th.mat-mdc-header-cell{
  white-space: nowrap;
  max-width:150px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right:10px;
}

td.mat-mdc-cell:first-of-type, td.mat-mdc-footer-cell:first-of-type, th.mat-mdc-header-cell:first-of-type{
  padding-left:20px;
}
td.mat-mdc-cell:last-of-type, td.mat-mdc-footer-cell:last-of-type, th.mat-mdc-header-cell:last-of-type{
  padding-right:20px;
}
.smal-width-field{
  display: inline-block;
  width:50px;
  vertical-align: middle;
  text-align:center;
}
.smallbtn{
  font-size:12px;
  font-weight: 400;
  padding:0;
  color:#B71C1C;
}
/*******/

.filtercol .mat-mdc-button{
  font-weight: 400;
  margin: 0 5px;
  padding: 2px 5px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
}
.filtercol .mat-mdc-button i{
  margin-left: 5px;
}/*
.paginationr span{
  margin-right:20px;
}*/
.paginationwidth{
  width:50px;
  display:inline-block;
  margin-right:10px;
  text-align:center;
}
.singlepnr{
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  padding:15px;
  background-color: #fff;
  border:2px solid transparent;
}

.singlepnr:hover{
  border-color:#ccc;
}

.reservationimage{
  margin-bottom: 10px;
}

.reservationimage img{
  display: block;
  margin:auto;
  width:25px;
  border-radius:50%;
  border-bottom:2px solid #cccccc;
}
.reservationimage span{
  font-size:10px;
}
.reservationPrice{
  font-size:15px;
}

.pnrliststatus{
  font-size:11px;
  font-weight: 500;
  text-transform: uppercase;
}

.pnrliststatus span{
  border:1px solid;
  padding:0 5px;
}
.pnrliststatus span.init{
  border-color: #B71C1C;
  color: #B71C1C;
}
.pnrliststatus span.linked{
  border-color: #155724;
  color: #155724;
}
.pnrliststatus span.issued,.pnrliststatus span.flown{
  border-color: #888;
}
.pnrliststatus span.cancelled,.pnrliststatus span.expired{
  border-color: #ff5722;
  color: #ff5722;
}

.ssrtimelimit{
  color:#777;
  font-style: italic;
  font-size: 11px;
}

.waitingtimelimit{
  color:#dba300;
}

.timelimit{
  color:#721c24;
}
.timelimit .mat-icon{
  vertical-align: middle;
  font-size:16px;
  width:20px;
  height:20px;
  line-height: 16px;
}

.listlocator{
  display: inline-block;
  margin-left: 10px;
}

.listlocator span{
  display: inline-block;
  padding:0 5px;
  border:1px solid;
}
.counterlimit{
  padding-top:5px;
  color:#B71C1C;
  font-size: 10px;
}
.locationspan{
  display: inline-block;
  margin:0 5px 5px 0;
}
.travelinfo .mat-icon{
  vertical-align: middle;
}
.pnrdeparture{
  color:#777;
  font-size: 11px;
}
.listpnrsinglepasseng{
  font-size:11px;
  text-transform: uppercase;
}
.pnrlistuser hr{
  margin:5px 0;
}
.variationpnr{
  color:#ff5722;
  padding:5px 0 0;
}
.variationpnr .mat-icon{
  vertical-align: middle;
  font-size:16px;
  width:20px;
  height:20px;
  line-height: 16px;
}
.dateaction{
  font-size:11px;
  color:#777;
  margin-bottom:5px;
}
.pnrlistuser{
  font-size:11px;
}
.linktopnr a{
  display: inline-block;
  padding:4px 5px;
}
.pricemuted{

}
.notesicons .mat-icon{
  font-size:16px;
  height:20px;
  width:20px;
  line-height: 16px;
  margin:0 5px;
}

.notesicons hr{
  margin:5px 0;
}

.textnotes{
  display: inline-block;
  margin:0 5px 0;
  padding:0 4px;
  border-left:1px dashed #B71C1C;
  border-right:1px dashed #B71C1C;
}

.textnotes h1,.textnotes h2,.textnotes h3,.textnotes h4,.textnotes h5,.textnotes h6,.textnotes p{
  margin:0;
}

.mat-chip-list-wrapper{
  display: inline-block!important;
}
.cpfilter h6{
  font-weight: 400;
  font-size: 14px;
}
.singlefop{
  border:2px solid transparent!important;
  cursor:pointer
}
.singlefop.fopselected{
  border:2px solid #B71C1C!important;
}

.filterSearch{
  background-color: #fff;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}
.titlecolor .mat-icon{
  background-color:#B71C1C;
  color:#fff;
  margin-right:10px;
  font-size:16px;
  border-radius:50%;
  line-height: 24px;
  text-align: center;
}

.tablepadding a{
  color:#999;
}
.toRead a{
  color: #B71C1C;
}

/*******/

.hotelDetailInfo{
  overflow-x:hidden;
  padding:0 10px;
}

.hotelSlide img{
  max-width: 100%;
  height:170px;
  margin:auto;
}

.singleHotelSlide{
  img{
    max-width: 100%;
    height:300px;
    margin:auto;
  }
  background-color: #eee;
}

.singleRoomDetails{
  overflow-x:hidden;
  margin-bottom:20px;
  padding:10px;
  border:1px solid #ccc;
  .mat-accordion .mat-expansion-panel{
    box-shadow:none;
    padding:0;
    border:none;
  }
  .mat-expansion-panel-header:hover{
    background-color: transparent!important;
  }
  .mat-expansion-panel-header{
    padding:0;
  }
  hr{
    margin:5px 0;
  }
  .rateText{
    font-size:12px;
  }
}

.amenity{
  display:inline-block;
  padding:2px 5px;
  font-size:10px;
  margin:2px 2px 2px 0;
  .material-icons{
    font-size:12px;
  }
}
.penalty{
  font-size:12px;
}

.bottomInfoBtn{
  background-color: #eee;
}

.roleTitle{
  color:#B71C1C;
  font-weight: bold;
  margin-bottom:5px;
}

.tagspan{
  background-color: #eee;
  border-radius: 8px;
  font-size:10px;
  text-transform:lowercase;
  padding:2px 5px;
  margin:0 5px 5px 0;
  display: inline-block;
}

.activeMenu{
  color:#B71C1C;
}
.dossierBar{
  background-color:#B71C1C;
  color: #fff;
}

.carsearch{
  .search-header-image{
    background-image: url(/assets/img/cars/Car-Icon.png);
    background-size: cover;
  }
}

.leftlog{
  background-image: url('/assets/img/themes/aereo.jpg');
  background-size: cover;
  background-position: center;
}
.hotelName, .hotelLocationIcon, .hotelListText{
  color: #3F51B5!important;
}
