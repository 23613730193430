.topbar{
  color:#fff;
  background-color: #424242;
  border-bottom:1px solid rgba(255, 255, 255, 0.12);
  box-shadow: none;
  padding:0;
  line-height: 50px;
}
.btn50top{
  color:#fff;
  background-color: #333;
  width: 50px;
  text-align:center;
  margin-right: 10px;
  border-bottom:1px solid rgba(255, 255, 255, 0.12);
  height: 50px!important;
}

a{
  color:#10b759;
  text-decoration: none;
}
a.mainlink,.mat-accordion .mat-expansion-panel:not(.mat-expanded), .mat-accordion .mat-expansion-panel:not(.mat-expansion-panel-spacing){
  border-radius: 0;
  border-bottom:1px solid rgba(255, 255, 255, 0.12);
}
a.mainlink,.sidemenu li a{
  color:#eee;
}
.sidemenu{
  /*border-left:1px solid;
  border-bottom:1px solid;
  border-color:rgba(0, 0, 0, 0.12);*/
}
.sidemenu li a{
  //border-bottom:solid 1px rgba(0, 0, 0, 0.12);
}
.sidemenu li a:before{
  content: "";
  border-radius: 50%;
  height: 5px;
  width: 5px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
  background-color: #eee;
}
.barcontainer a.active-link{
  background-color: rgba(0,0,0,.1);
}
.userDetails{
  border-bottom:solid 1px rgba(255, 255, 255, 0.12);
}
.left_bar{
  background-color: #333;
}
.default_panel{
  background-color: #424242;
}
.default_heading{
  border-bottom:1px solid rgba(255, 255, 255, 0.125);
}
.default_footer{
  border-top:1px solid #333;
}
.mat-mdc-menu-item.active{
  background-color: #333;
}

.chartpanel{
  background-color: #424242;

}
.chartpanel apx-chart{
  color:#333;
}
.pagetitle{
  border-top:solid 1px rgba(255, 255, 255, 0.12);
}
.chartpanel{
  background-color: #424242;
}
.datasetpanel{
  background-color: #424242;
}
.isborderleft{
  border-left:3px solid #607d8b;
}
.loginpage{
  background-color: #303030;
  color:#fff;
}
.bgopacity{
  background-color: rgba(0,0,0,0.4);
}
.borderbottom{
  border-bottom:1px solid rgba(255, 255, 255, 0.125);
}
.smalltext{
  color:#ccc;
}
.scrolling::-webkit-scrollbar-thumb {
  background-color: #222;
  outline: 1px solid slategrey;
}
.absoluteLoading{
  background-color: rgba(0,0,0,0.4);
}
.modal-content{
  background-color:#444;
}
.modal-header{
  border-bottom:1px solid rgba(0,0,0,0.4);
}
.modal-footer{
  border-top:1px solid rgba(0,0,0,0.4);
}
button.close{
  text-shadow: none;
}
.settingsBar{
  background-color: #333;
  color:#fff;
}
.settingsBackDrop{
  background-color: rgba(255,255,255,0.3);
}
.mat-expansion-panel-header-title .mat-icon{
  color:#ccc;
}
.searchrow{
  border-bottom:1px solid #666;
}
.searchrow,.noresult{
  background-color: #424242;
}
.searchtext{
  color:#ffd740;
}
.profiletopdetails{
  position:relative;
  padding:20px;
  border:1px solid rgba(72, 94, 144, 0.16);
  background-color: #424242;
}

.counteruser{
  margin-bottom:5px;
  color:#eee;
  padding:10px 20px;
  background-color: #333;
}
.card{
  border:1px solid rgba(72, 94, 144, 0.16);
  background-color: #424242;
}
.flightsettings{
  background-color:#424242;
}
.settsubtitle{
  color:#eee;
}
.companytop:before{
  content:"";
  position:absolute;
  top:0;
  right:0;
  width:200%;
  height:100%;
  background-color: #333;
  transform:rotate(25deg);
  /*border-top:2px solid #4d79f6;*/
}

.autoNotificationBar,.manualNotificationBar{
  background-color: #333;
  color:#fff;
}
.BackdropAuto,.BackdropManual{
  background-color: rgba(255,255,255,0.3);
}
.notificationsingle:hover{
  background-color: #333;
}
.notificationsingle.unread{
  background-color: #555;
}
.notificationsingle .notificationIcon{
  margin-top:10px;
  background-color: #222;
  border-radius: 50%;
  text-align:center;
  height: 35px;
  width: 34px;
  display: inline-block;
  line-height: 34px;
  border:1px solid #333;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}
.companySubSpan{
  background-color:#666;
  color:#fff;
}

/****/

/** SEARCH FLIGHT FORM MODULE **/

.searchForm{
  background-color: #424242;
}
.smallmenu{
  font-size: 12px;
  width: 100%;
  border-bottom: 1px solid #999;
  text-align: left;
  padding:0 5px;
  font-weight: normal;
  border-radius:0;
  height: 56px;
}

.inline-width{
  display:inline-block;
  width:100px;
  margin:0 5px;
}
.lightbg{
  background-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  border-left:3px solid #333;
  padding-top:5px;
  padding-left:20px;
  position:relative;
}
.flightnumeration{
  position:absolute;
  left:-13px;
  top:30%;
  width:20px;
  height:20px;
  line-height: 20px;
  background-color: #333;
  border-radius: 50%;
  text-align:center;
  color:#fff;
}

.singleiata{
  display:inline-block;
  text-transform: uppercase;
  padding:2px 5px;
  font-size:10px;
  font-family: monospace;
  background-color: #333;
  border:1px solid #222;
  margin:0 8px 8px 0;
  position: relative;
  border-radius: 4px;
}

.cancelicon{
  cursor:pointer;
  position: absolute;
  top: -6px;
  right: -8px;
  font-size: 16px;
  display: block;
  width: 14px;
  height: 14px;
  line-height: 14px;
  text-align: center;
  background-color: #333;
  border: 1px solid #222;
  border-radius: 50%;
}
.handle-terminal{
  position: absolute;
  top: 6px;
  right: 6px;
  color: #ccc;
  cursor: move;
  width: 18px;
  height: 18px;
}
.fakefield{
  padding: 10px 8px 4px;
  line-height: 20px;
  border-bottom: 1px solid #999;
  color:rgba(0,0,0,.54);
  background-color: #fff!important;
  position: relative;
  background-size: 1rem 1rem!important;
  background-image: linear-gradient(45deg,rgba(0,0,0,.1) 25%,transparent 25%,transparent 50%,rgba(0,0,0,.1) 50%,rgba(0,0,0,.1) 75%,transparent 75%,transparent)!important;
  cursor: not-allowed;
}
.hotelName, .hotelLocationIcon, .hotelListText{
  color: #3F51B5!important;
}
