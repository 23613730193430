@use '@angular/material' as mat;

@include mat.core();

$theadmin-primary: mat.define-palette(mat.$indigo-palette);
$theadmin-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$theadmin-warn: mat.define-palette(mat.$red-palette);

$theadmin-theme: mat.define-light-theme((
  color : (
    primary : $theadmin-primary,
    accent : $theadmin-accent,
    warn : $theadmin-warn,
  )
));

$sbt-primary: mat.define-palette(mat.$light-blue-palette);
$sbt-accent: mat.define-palette(mat.$light-blue-palette, A200, A100, A400);
$sbt-warn: mat.define-palette(mat.$orange-palette);
$sbt-primary: mat.define-light-theme((
  color : (
    primary : $sbt-primary,
    accent : $sbt-accent,
    warn : $sbt-warn,
  )
));

$gat-primary: mat.define-palette(mat.$blue-grey-palette);
$gat-accent: mat.define-palette(mat.$light-blue-palette, A200, A100, A400);
$gat-warn: mat.define-palette(mat.$red-palette);
$gat-primary: mat.define-light-theme((
  color : (
    primary : $gat-primary,
    accent : $gat-accent,
    warn : $gat-warn,
  )
));

$dark-primary: mat.define-palette(mat.$blue-grey-palette);
$dark-accent:  mat.define-palette(mat.$amber-palette, A200, A100, A400);
$dark-warn:    mat.define-palette(mat.$deep-orange-palette);
$dark-theme:   mat.define-dark-theme((
  color : (
    primary : $dark-primary,
    accent : $dark-accent,
    warn : $dark-warn,
  )
));

$purple-primary: mat.define-palette(mat.$purple-palette);
$purple-accent:  mat.define-palette(mat.$purple-palette, A200, A100, A400);
$purple-warn:    mat.define-palette(mat.$purple-palette);
$purple-theme:   mat.define-light-theme((
  color : (
    primary : $purple-primary,
    accent : $purple-accent,
    warn : $purple-warn,
  )
));

$grey-primary: mat.define-palette(mat.$light-blue-palette, 700);
$grey-accent:  mat.define-palette(mat.$pink-palette, A200, A100, A400);
$grey-warn:    mat.define-palette(mat.$red-palette);
$grey-theme:   mat.define-light-theme((
  color : (
    primary : $grey-primary,
    accent : $grey-accent,
    warn : $grey-warn,
  )
));

$green-primary: mat.define-palette(mat.$light-green-palette);
$green-accent:  mat.define-palette(mat.$pink-palette, A200, A100, A400);
$green-warn:    mat.define-palette(mat.$red-palette);
$green-theme:   mat.define-light-theme((
  color : (
    primary : $green-primary,
    accent : $green-accent,
    warn : $green-warn,
  )
));

$red-primary: mat.define-palette(mat.$indigo-palette);
$red-accent:  mat.define-palette(mat.$pink-palette, A200, A100, A400);
$red-warn:    mat.define-palette(mat.$red-palette);
// $red-theme:   mat.define-light-theme($red-primary, $red-accent, $red-warn);

@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,400,500,600,700|Rubik:400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&display=swap');
@import 'node_modules/angular-notifier/styles/core.scss';
@import 'node_modules/angular-notifier/styles/themes/theme-material.scss';
@import 'node_modules/angular-notifier/styles/types/type-default.scss';
@import 'node_modules/angular-notifier/styles/types/type-error.scss';
@import 'node_modules/angular-notifier/styles/types/type-success.scss';
@import 'node_modules/angular-notifier/styles/types/type-warning.scss';
@import 'lightgallery/scss/lightgallery';

@include mat.all-component-themes($theadmin-theme);
.main-theme {
  @import "assets/themes/main-theme.scss";
}

.third-theme {
  @include mat.all-component-colors($purple-theme);
  @import "assets/themes/third-theme.scss";
}

.green-theme {
  @include mat.all-component-colors($green-theme);
  @import "assets/themes/green-theme.scss";
}
.red-theme {
  /*@include mat.all-component-colors($theadmin-theme);*/
  @import "assets/themes/red-theme.scss";
}
.yellow-theme {
  /*@include mat.all-component-colors($theadmin-theme);*/
  @import "assets/themes/yellow-theme.scss";
}
.sbt-theme {
  @include mat.all-component-colors($sbt-primary);
  @import "assets/themes/sbt-theme.scss";
}

.gat-theme {
  @include mat.all-component-colors($gat-primary);
  @import "assets/themes/gat-theme.scss";
}

.second-theme {
  @include mat.all-component-colors($dark-theme);
  @import "assets/themes/second-theme.scss";
}

.b2b-theme {
  @include mat.all-component-colors($grey-theme);
  @import "assets/themes/b2b-theme.scss";
}

.row{
  margin:0 -5px;
}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
  padding:0 5px;
}

.orangecolor{
  color:orange;
}

.redcolor{
  color:red;
}

.greencolor{
  color:green;
}

h4{
  font-size: 14px;
  margin: .6em 0;
  font-weight: 500;
}

html, body {
  height: 100%;
  margin:0;
  padding:0;
  position: fixed;
  width: 100%;
  top:0;
}
body {
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size:14px;
  font-weight: 400;
  position: relative
}

h5{
  font-size:14px;
  margin:.3em 0;
}

p{
  margin:5px 0;
}

img{
  max-width: 100%;
}

.mat-drawer.mat-drawer-side{
  z-index:1!important;
}
.mat-drawer-transition .mat-drawer-content{
  overflow-x: hidden;
}

.topbar{
  position:fixed;
  top:0;
  left:0;
  right:0;
  height:50px;
  padding: 5px;
  box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);
  z-index:2;
  background-color: #fff;
}

.maincontainer{
  padding:0;
  overflow-x: hidden;
  display:block;
  height: 100%;
  position:relative;
  padding-top:50px;
}
.mat-drawer-container{
  height:100%;
}
.marginbottom5{margin-bottom:5px;}
.marginbottom10{margin-bottom:10px;}
.marginbottom20{margin-bottom:20px;}
.marginbottom30{margin-bottom:30px;}

.padding5{padding:5px;}
.padding10{padding:10px;}
.padding15{padding:15px;}
.padding20{padding:20px;}
.padding25{padding:25px;}

.marginleft5{margin-left: 5px!important;}

.absoluteLoading{
  position:absolute;
  top:0;
  left:0;
  bottom:0;
  right: 0;
  z-index:99;
  p{
    font-weight: 500;
    margin:0 0 10px!important;
  }
}

button,a,select,input,textarea{
  outline: none!important;
}

.main-sidenav{
  width:240px;
}

.mainmenu .mat-expansion-panel:not([class*=mat-elevation-z]){
  box-shadow: none;
}

.mat-accordion .mat-expansion-panel:last-of-type{
  border-radius: 0!important;
}
.mainmenu .mat-expansion-panel-body{
  padding:0;
}

a,mainlink,.mat-expansion-panel-header{
  font-size:13px;
}

/*******/

.barcontainer::-webkit-scrollbar {
  display: none;
}

.default_panel{
  border:1px solid rgba(72, 94, 144, 0.16);
  border-radius: 2px;
  box-shadow:0 0 10px rgba(28, 39, 60, 0.05);
}
.default_heading{
  padding:10px 20px;
  font-weight: 500;
  font-size:14px;
}
.default_content{
  padding:20px 20px;
  position: relative;
}
.default_footer{
  padding:10px 20px;
}
.mat-mdc-card{
  border:1px solid rgba(72, 94, 144, 0.16)!important;
  border-radius: 2px!important;
  box-shadow:0 0 10px rgba(28, 39, 60, 0.05)!important;
}
.mat-icon{
  vertical-align: middle;
}
.marginright10{
  margin-right:10px;
}
.pagetitle{
  padding:15px 20px 10px 20px;
}

h5.titlesect{
  font-size:11px;
  font-weight: 500;
  text-transform: uppercase;
  margin:0 0 5px;
}
.numericData{
  font-size: 22px;
  font-weight: 400;
  vertical-align: middle;
  display: inline-block;
  font-family: 'Rubik', sans-serif;
}
.monthstatus{
  position:absolute;
  bottom:5px;
  right:5px;
  font-size:11px;
}
.monthstatus span{
  font-size: 11px;
  margin-right:4px;
  font-weight: 500;
}
.good span{
  color:#10b759;
}
.bad span{
  color:#dc3545;
}

.btn50top{
  display: inline-block;
  position:relative;
}
.datasetpanel{
  padding:20px 20px;
  border:1px solid rgba(72, 94, 144, 0.16);
  border-radius: 2px;
  box-shadow:0 0 10px rgba(28, 39, 60, 0.05);
  position:relative;
}
.datasetpanel .material-icons{
  font-size: 12px;
  height: 12px;
  width: 12px;
  line-height: 10px !important;
  vertical-align: middle;
}

.mat-mdc-menu-panel{
  min-width: 220px!Important;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.btndivider{
  border-bottom:1px solid rgba(0,0,0,.4);
  margin:10px 0;
}

.apexcharts-canvas.apexcharts-theme-dark{
  background-color: transparent!important;
}
.smalltext{
  font-size:12px;
}
.maincontent{
  margin:auto;
  padding-bottom:30px;
  overflow-x: hidden;
}
.boxedWh{
  max-width: 1200px;
}
.smfont{
  font-size:12px;
  p{
    margin:0 0 5px;
  }
}
.smalltitle{
  margin-bottom:5px;
  font-weight: 500;
}

.avataricon{
  width:30px;
  height: 30px;
  border-radius: 50%;
}

button.small-md{
  height:24px;
  width:24px;
  line-height: 24px;
  .mat-icon{
    font-size:18px;
    height:18px;
    line-height: 16px!important;
    width: 18px;

  }
}
a.small-md-text,button.small-md-text{
  height:24px;
  min-width:24px;
  line-height: 24px;
  font-size:12px;
}

.scrolling{
  max-height: 250px;
  overflow-y:auto;
}
.scrollingbig{
  max-height: 350px;
  overflow-y:auto;
}
.scrolling::-webkit-scrollbar {
  width: 5px;
}

.scrolling::-webkit-scrollbar-track {
  background-color: transparent;
}

.modal{
  padding-top:60px;
  background-color: rgba(0,0,0,.5);
}

.modal-backdrop{
  display: none;
}
.full-width{
  width:100%;
  min-width: 100px;
}
.rotatingloop {
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.mat-mdc-progress-spinner{
  margin:auto;
}
.marginfield{
  margin-right:10px;
  margin-bottom:10px;
}
.profilecontainer .mat-expansion-panel-header{
  padding:0!important;
}
.profilecontainer .mat-expansion-panel-body{
  padding:0!important;
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  font-size: 12px;
  font-weight: normal;
  padding:5px 10px;
  color: white;
  background-color: #3f51b5;
  display: inline-block;
  cursor: pointer;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: #4d79f6;
}

.inputfile + label .mat-icon{
  margin-right:5px;
}
.image-preview{
  max-width: 128px;
  max-height: 128px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  border-bottom: 4px solid #4d79f6;
}
.image-preview img{
  max-width:100%;
}
.error{
  font-size:12px;
  color:red;
  font-style: italic;
}

.snackerror{
  color:red;
  background-color: #fff;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}

.snackerror .mat-mdc-snack-bar-action{
  color:#000;
}

.autoNotificationBar,.manualNotificationBar{
  position:fixed;
  top:0;
  bottom:0;
  width:300px;
  right: -300px;
  z-index:999;
  transition:all linear .2s;
  overflow-y: auto;
}
.BackdropAuto,.BackdropManual{
  position:fixed;
  top:0;
  bottom:0;
  right:-150%;
  width:100%;
  z-index:998;
}
.autoNotificationBar.open,.manualNotificationBar.open{
  right:0;
  transition:all linear .2s;
}
.BackdropAuto.open,.BackdropManual.open{
  right:0;
}
.tabtitle{
  margin-right:5px;
  display: inline-block;
}
.badge-material .mat-mdc-icon-button .mat-icon{
  height:12px;
  line-height: 12px;
  font-size:12px;
  width: 12px;
}

.badge-material .mat-mdc-icon-button{
  height:24px;
  width:24px;
  line-height: 24px;
}

.smallWidth{
  max-width: 100px;
}

.shaking{
  animation: shake 1s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

/*****/

.flightnumeration .mat-icon{
  font-size:14px;
  height:14px;
  width:14px;
  line-height: 11px;
}
.flightnumeration .toright{
  transform: rotate(90deg);
}
.flightnumeration .toleft{
  transform: rotate(-90deg);
  margin-top:-2px;
}

.formActionsButtons{
  // padding-top: 20px;
}
.formActionsButtons .mat-mdc-raised-button{
  height:25px;
  line-height: 25px;
  margin:0 5px 0 0;
  font-size:12px;
}
.searchoptions .mat-mdc-checkbox{
  margin-right:15px;
  margin-bottom:10px;
}

.priceDetails{
  margin-bottom: 15px;
}

.pnrActionPart button{
  margin-left:4px;
}

.pnrActionPart .material-icons{
  font-size:18px;
  margin-right:5px;
  vertical-align:sub!important;
}
.buttonrow button, .buttonrow a{
  margin:0 5px 5px 0;
  height:30px;
  line-height: 30px;
  font-size: 12px;
}
.material-icons{
  vertical-align: middle;
}
.pnrActionsButtons .mat-mdc-raised-button, .smallActionButtons .mat-raised-button, .smallActionButtons .mat-mdc-outlined-button {
  font-size: 12px;
  padding: 3px 5px;
  line-height: 20px;
  height: 30px;
  margin-right: 5px;
}
.mat-mdc-card-subtitle, .mat-mdc-card-content{
  font-size: 12px;
}

.roomTitle{
  font-size:12px;
  margin-bottom:10px;
  .material-icons{
    margin-right: 5px;
    vertical-align: bottom;
  }
}

.terminal {
  position: relative;
  width: 100%;
  max-width: 100%;
  padding-top: 45px;
  margin-top: 8px;
  overflow: hidden;
  background-color: rgb(15, 15, 16);
  color:#eee;
}

.terminal pre{
  color:#eee;
}

.terminal::before {
  content: "\2022 \2022 \2022";
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  background: rgb(58, 58, 58);
  color: #c2c3c4;
  width: 100%;
  font-size: 2rem;
  line-height: 0;
  padding: 14px 0;
  text-indent: 4px;
}

.terminalcontent{
  max-height: 400px;
  overflow-y:auto;
  overflow-x: hidden;
  button{
    color: #c2c3c4!important;
  }
}

.terminalcontent::-webkit-scrollbar {
  width: 5px;
  margin-right:2px;
}

.terminalcontent::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.terminalcontent::-webkit-scrollbar-thumb {
  background-color: #4a4a4a;
  outline: 1px solid slategrey;
}

.terminalcontent {
  font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
  color: #ccc;
  padding: 1rem 1rem 1rem;
  margin: 0;
}

.ancillaryTabs{
  .tabButton{
    cursor:pointer;
    display:inline-block;
    padding:5px 10px;
    margin:0 0 0;
    font-size:11px;
  }
  .ancillariesTable{
    font-size:12px;
  }
  .material-icons{
    font-size:18px;
  }
  .included{
    color:green;
  }
  .notIncluded{
    color:red;
  }
  .tabContent{
    padding:20px;
  }
}

.filterSearch{
  position:fixed;
  top:51px;
  left:-280px;
  bottom:0;
  width:280px;
  z-index:9999;
  transition:all linear .2s;
  padding:30px 10px;
  overflow-y:auto;
}

.openfilters{
  left:0;
  transition:all linear .2s;
}

.filtersearchBackdrop{
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  display: none;
  z-index: 9998;
  background-color: rgba(0,0,0,0.6);
}

.openfiltersearchBackdrop{
  display:block;
}

.carrierFilter{
  span{
    margin:0 5px 5px 0;
    display: inline-block;
    border-radius:50%;
  }
}
.singleFilterCarrier{
  border:2px solid #ccc;
  transition: all linear .2s;
  cursor: pointer;
}
.greenborder{
  border:2px solid #00b800;
  border-radius: 0!important;
  transition: all linear .2s;
}
.filterCarrier{
  width:30px;
  border-radius:50%;
}
.filterRecom{
  position: fixed;
  bottom:5px;
  right: 20px;
  z-index: 999;
  .filters, .mat-mdc-mini-fab, .mat-mdc-focus-indicator, .mat-mdc-button-touch-target, .mat-mdc-button-ripple{
    width:30px!important;
    height:30px!important;
    .mat-icon{
      top: 0!important;
      height: 18px!important;
      width: 18px!important;
      font-size: 18px!important;
      display: flex!important;
      align-self: center!important;
    }
  }
}

.filterCar{
  position: fixed;
  bottom:5px;
  right: 20px;
  z-index: 999;
  button{
    margin-right:10px;
  }
}

.diInline50{
  display: inline-block;
  width:50%;
  min-width: auto!important;
}
.priceFilter{
  font-size:12px;
}

.barcontainer{
  .menuicon{
    font-size: 18px;
    margin-right: 5px;
  }
  .mat-expansion-panel-header{
    padding: 0 14px;
  }
  .mat-expansion-panel-header.mat-expanded{
    height: 48px;
  }
}
.titlecolor{
  font-size:14px;
  font-weight: normal;
}

.mat-tab-label .mat-tab-label-content .mat-icon{
  font-size:20px;
  margin-right:5px;
}

.mat-drawer-content{
  position: initial!important;
}

.topMenuBar{
  // max-width:1200px;
  margin:auto;
  transition:all linear .2s;
}

.menuTop{
  .mat-button{
    font-weight: normal;
    font-size:12px;
  }
}
.mat-menu-item{
  font-size:13px!important;
  height:35px!important;
  line-height: 35px!important;
}
@media (max-width: 768px) {
  .menuTop{
    position:fixed;
    width:280px;
    left:-280px;
    top:50px;
    bottom:0;
    z-index:9999;
    background-color: #fff;
  }
  .menuTopBackDrop{
    background-color: rgba(0,0,0,.5);
    position:fixed;
    left:-100%;
    top:50px;
    bottom:0;
    width:100%;
    z-index:9998;
  }
  .visibleMenuTop{
    left:0;
  }
  .menuTop .mat-mdc-button {
    font-weight: normal;
    font-size: 12px;
    display: block;
    width: 100%;
    text-align: left;
  }
  .listButton{
    vertical-align: top!important;
  }
  .menuButtons{
    vertical-align: top!important;
  }
  .checkBoxes{
    vertical-align: top!important;
  }
}

.mat-mdc-tab-body .travelDetails{
  padding-top:20px;
}

.bottomInfoBtn{
  padding:2px 8px;
  margin: 4px 2px;
  border-radius:2px;
  display:inline-block;
  font-size:10px;
  cursor:pointer;
  text-transform: uppercase;
}

.hotelChain{
  font-size:10px;
  text-transform: uppercase;
  font-weight: 500;
}

.paddingtop40{
  padding-top:40px;
}

@media (max-width: 580px) {
  .row{
    margin:0;
  }
  .topbar{
    .row{
      margin:0 -5px;
    }
  }
  .padding20 {
    padding: 20px 0;
  }
  .mat-tab-body-content .padding20{
    padding:20px;
  }
  .loginpage .padding20{
    padding:20px;
  }

  .logoImg img{
    max-width: 70px;
    height: auto!important;
  }

  .paddingtop40{
    padding-top:0;
  }
}

.pointer{
  cursor: pointer;
}
.typeRow{
  label{
    display: block;
  }
  .radio-button {
    margin:5px 5px 0;
  }
  label.mat-radio-label{
    margin-bottom:0!important;
    display: inline-block;
  }
  .mat-radio-label-content{
    vertical-align: super;
  }
}

#toPrint{
  font-size:12px;
  min-width: 900px;
  overflow-x: auto;
  // display: none;
  padding:10px;
  border:1px solid #ccc;
  background-color:#fff;
  .companyImage{
    max-height:85px;
    max-width:200px;
  }
  .reservationimage img{
    width:30px;
    display:inline-block;
    border-radius:50%;
    border:1px solid #ccc;
    margin:0 5px 0 0;
    vertical-align: middle;
  }
  .material-icons{
    vertical-align: middle;
  }
  .pnrdeparture{
    font-size:11px;
    color:#999;
  }
  table td{
    width:25%;
  }
  .operatingImage{
    width:30px;
    border-radius: 50%;
    border:1px solid #ccc;
  }
  .backPrint{
    padding:10px;
  }
  .farePart{
    font-size:12px;
  }
  .printFare{
    font-size:12px;
  }
  .infoBag{

  }
  .infoTar .material-icons, .infoBag .material-icons{
    font-size:18px;
    margin-right:5px;
    vertical-align: middle;;
  }
  .maincabin{
    font-size: 10px;
    text-transform:uppercase;
  }

  .fakeTable{
    border:1px solid #ccc;
    padding:10px;
    font-size:11px;
  }

  .fakeTable hr{
    margin:5px 0;
  }

  table td.infoIti{
    background-color:#eee;
  }
}

.default_content .notificationsActions{
  width: 100%!important;
  position:relative!important;
  bottom:0;
  margin-bottom:10px;
}

.default_content .autonotificationlist{
  padding-bottom:0!important
}

.fixedLoader{
  position:fixed;
  top:0;
  left:0;
  width: 100%;
}

.negoDetails{
  padding:10px 15px;
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.corporateDetails{
  padding:10px 15px;
  color: #fff;
  background-color: #7B1E7A;
  border-color: #5e135d;
}

.corporateBorder{
  border-top: 2px solid #7B1E7A;
}

.loginContainer{
  width:100%;
  max-width: 400px;
  margin:auto;
  padding:20px;
  background-color: #fff;
  border:1px solid #eee;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}

.paddingTop40{
  padding-top:40px;
  transition: all linear .2s;
}

.bottomLarge{
  width:900px!important;
  max-width:98%!important;
}

.oldFlight{
  background-image:url('/assets/img/red_dotted_flight_80d.png')!important;
}

.columnName{
  background-color: #222!important;
  color:#fff;
}

.del{
  text-decoration: line-through;
  color:red;
}

.hotelRating .material-icons{
  color: #ffaf00;
  font-size: 18px;
}

.homeWidg{
  position:relative;
  font-family: 'Montserrat', sans-serif;
  margin:0 5px 20px;
  background-color: #fff;
  padding:10px 20px;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  border-radius: 3px;
  h5{
    margin:0 0 15px;
    font-weight: 500;
  }
  .widAction{
    font-size:12px;
    padding:2px 0;
    margin:5px 0 5px;
    cursor:pointer;
    transition:all linear .2s;
    font-weight:400;
  }
  .widAction:hover{
    // padding-left:3px;
    transition:all linear .2s
  }
  .topfix{
    transition:all linear .2s;
    position:absolute;
    top: -25px;
    right: 10px;
    font-size: 20px!important;
  }
}
.dialog h2,.dialog h1{
  font-size:14px!important;
  margin:5px 0!important;
  padding:0 0 10px;
}

.alerts-tooltip {
  text-align: center;
  padding: 0 50px;
}
.alerts-tooltip [tooltip] {
  display: inline-block;
  margin: 50px 20px;
  width: 180px;
  height: 50px;
  border: 1px solid gray;
  border-radius: 5px;
  line-height: 50px;
  text-align: center;
}
.ng-tooltip {
  position: absolute;
  max-width: 150px;
  font-size: 14px;
  text-align: center;
  color: #f8f8f2;
  padding: 3px 8px;
  background: #fff;
  border: 1px solid #333;
  border-radius: 5px;
  z-index: 1000;
  opacity: 0;
}
.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}
.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  transform: translateX(-50%) !important;
  margin-left: -5px;
  border-width: 5px;
  border-color: black transparent transparent transparent;
}
.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%) !important;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent black transparent;
}
.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent black;
}
.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent black transparent transparent;
}
.ng-tooltip-show {
  opacity: 1;
}

.custom-tooltip {
  position: absolute;
  display: none;
}


.tooltip-icon:hover {
  .custom-tooltip {
    display: block!important;
    margin-left: 5px;
  }

}


.warn-alert {
  padding: 10px;
  margin: 10px;
  background: #FFAF00;
  border-radius: 10px;
  color: #fff;
}

.danger-alert {
  padding: 10px;
  margin: 10px;
  background: #D2504D;
  border-radius: 10px;
  color: #fff;
}
.vertical{
  vertical-align: middle;
}
.smalerIcon.material-icons{
  font-size:14px;
  height:14px;
  line-height:14px;
  display:inline-block;
  vertical-align:middle;
}

.smaler{
  font-size:14px;
  height:14px;
  line-height:14px;
  display:inline-block;
  vertical-align:middle;
}

.fopsContainer{
  overflow-x: hidden;
}

.animateMarkup{
  animation: jump 1.2s infinite;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@keyframes jump {
  0% { transform: translate(-50%, -50%) scale(1.25, 0.75); }
  50% { transform: translate(-50%, -150%) scale(1, 1); }
  55% { transform: translate(-50%, -150%) rotate(15deg); }
  60% { transform: translate(-50%, -150%) rotate(-15deg); }
  65% { transform: translate(-50%, -150%) rotate(15deg); }
  70% { transform: translate(-50%, -150%) rotate(-15deg); }
  100% { transform: translate(-50%, -50%) scale(1.25, 0.75); }
}

.dashmenu{
  margin:0;
  padding:0;
  list-style: none;
}

.dashmenu li{
  margin:0 0 10px;
}

.dashmenu a{
  text-decoration:none;
  padding:5px 10px;
  &:hover{
    background-color: #eee;
  }
}
.singleiata{
  font-family: 'IBM Plex Sans', sans-serif!important;
  text-transform: initial!important;
  font-size:12px!important;
}

.wordWrap{

  .mat-checkbox-layout{
    white-space: initial !important;
  }
  .mat-checkbox-inner-container{
    margin: 0 8px 0 0 !important;
    top:5px !important;
  }
}
.smalIcon{
  font-size:14px;
  margin-right:3px;
}
.verySmall{
  font-size:10px;
}

.searchHotel .mat-radio-label-content{
  margin-right:10px;
}

.clearB{
  clear:both;
}
.min,.max{
  display: none!important;
}
.info.max,.info.min{
  display: block!important;
}

.range-slider input{
  width:88%!important;
}
.range-slider input[type=range].ghost{
  width: 100%!important;
}

.mat-tab-header-pagination{
  z-index:1!important;
}

.costCenterInputing{
  font-size:13px;
}

.costTilte{
  color: #3f51b5;
}

.actionButton{
  background-color: #506fd9;
  color:#fff;
  border:none;
  border-radius:4px;
  padding:5px 10px;
  outline:none;
}

.abortButton{
  background-color: #6e7985;
  color:#fff;
  border:none;
  border-radius:4px;
  padding:5px 10px;
  outline:none;
}

button:disabled,
button[disabled]{
  opacity: .5;
}

.numberAction{
  background-color: transparent;
  border:none;
  outline:none;
}

.customInputNumber{
  width: 20px;
}

.singleField{
  .material-icons{
    font-size:18px;
    color:#888;
  }
  &:hover{
    background-color: #eee;
  }
}

.addOptionBtn{
  border:1px solid #ddd;
  background-color: #fff;
  border-radius:4px;
  font-size:12px;
}
.dsButtonPrimary{
  min-width: 100px;
  padding: 5px 8px;
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  background: #4598D6;
  color:#fff!important;
  font-family: Roboto, "Helvetica Neue", sans-serif;;
  font-size: 12px;
  font-weight: 500;
  cursor:pointer;
}
.dsButtonSecondary{
  min-width: 100px;
  padding: 5px 8px;
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #D6D6D6;
  background: #FFF;
  color:#000!important;
  font-family: Roboto, "Helvetica Neue", sans-serif;;
  font-size: 12px;
  font-weight: 500;
  cursor:pointer;
}

.dsButtonOnTop{
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: #FFF7EE;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  line-height: 25px;
  color:#fd7e14!important;
  box-shadow:0 0 8px 0 rgba(0, 0, 0, 0.2);
  .material-icons{
    font-size:18px;
  }
}

.noRes{
  background: #F6F6F6;
  color: #A7A7A7;
  font-family: Roboto, "Helvetica Neue", sans-serif;;
  font-size: 22px;
  font-style: italic;
  font-weight: 300;
  padding:30px;
}

.widgetTitle{
  color: #262626;
  font-family: Roboto, "Helvetica Neue", sans-serif;;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding:16px 16px 20px;
  background-color: #fff;
}

.newButtonTab{
  display: inline-block;
  background-color: #fff;
  border:none;
  border-bottom:2px solid transparent;
  color: #262626;
  text-align: center;
  font-family: Roboto, "Helvetica Neue", sans-serif;;
  font-size: 12px;
  font-weight: 400;
  padding:5px 15px;
}

.newButtonTabActive{
  display: inline-block;
  color:rgba(235, 82, 129, 1);
  background-color: #fff;
  border:none;
  border-bottom:2px solid rgba(235, 82, 129, 1);
}


.newTabButton{
  display: inline-block;
  background-color: transparent;
  border:none;
  border-bottom:2px solid transparent;
  color: #262626!important;
  text-align: center;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding:5px 20px;
}

.newTabButtonActive{
  display: inline-block;
  color:rgba(235, 82, 129, 1)!important;
  background-color: transparent;
  border:none;
  border-bottom:2px solid rgba(235, 82, 129, 1);
  padding:5px 20px;
  font-size:12px;
}


.searchCard{
  background-color: #fff;
  padding:24px;
  /*border: 1px solid #D6D6D6;*/
  font-size: 12px;
}

.searchCard img{
  max-width: 100%;
  border-radius: 50%;
}
.formIcon{
  width: 40px;
}

.searchtitle{
  margin-left:20px;
}

.titleSup{
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin-bottom:12px;
  font-weight: bold;
  line-height:1;
}

.titleSub{
  font-size: 12px;
  color:rgba(0, 0, 0, 0.54);
  margin-bottom:12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  line-height:1;
}

.searchContainer{
  background-color: #F6F6F6;
}

.formTopButtons{
  padding:10px;
  text-align: right;
}

.formTopAction{
  display: inline-block;
  margin-left:10px;
  background-color: #fff;
  border-radius: 50%;
  padding:5px;
  border: 1px solid #ccc;
  position:relative;
  cursor:pointer;
}

.formTopAction:hover, .formTopAction.ActiveOption{
  background-color: #4598D6;
  color: #fff;
  border-color: #4598D6;
}

.formTopAction.reset:hover{
  background-color: red;
  color: #fff;
  border-color: red!important;
}

.formTopAction.disabled{
  pointer-events: none;
  opacity: .4;
}

.formTopAction:hover:after, .formTopAction.ActiveOption:after{
  position:absolute;
  content:'';
  top:-5px;
  left:-5px;
  right:-5px;
  bottom:-5px;
  border: 1.5px solid #4598D6;
  border-radius: 50%;
}

.formTopAction.reset:hover:after{
  position:absolute;
  content:'';
  top:-5px;
  left:-5px;
  right:-5px;
  bottom:-5px;
  border: 1.5px solid red;
  border-radius: 50%;
}

.newStandardFields {
  border-top: 1px solid #D6D6D6;
  padding: 10px 10px 0;
  margin: 0;
}

.newOptionsRow{
  padding:10px 10px 5px 10px;
}

.newButtonPrimary{
  background-color:#4598D6;
  border:1px solid #4598D6;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  font-family: Roboto, "Helvetica Neue", sans-serif;;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 8px 12px;
}

.newButtonCalendar{
  background-color:#fff;
  border:1px solid #4598D6;
  border-radius: 4px;
  color: #4598D6;
  text-align: center;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 8px 12px;
  &:disabled{
    color:#fff;
    background-color:#ccc;
    border:1px solid #ccc;
  }
  .material-icons{
    font-size:14px;
    height:auto;
  }
}

.newButtonPrimary:disabled{
  background-color:#ccc;
  border:1px solid #ccc;
}

.newButtonSecondary{
  color:#4598D6;
  background-color: transparent;
  border:none;
  text-transform: uppercase;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}

.newIconButton{
  background-color: transparent;
  color:#A7A7A7;
  border:none;
}

.newButton{
  background-color:#fff;
  border:1px solid #fff;
  border-radius: 4px;
  color: #262626;
  text-align: center;
  font-family: Roboto , "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 8px 12px;
}

.newButton:hover{
  border-color: #4598D6;
}

.newSingleOptionRow{
  padding:10px 10px 5px 10px;
  border-top: 1px solid #D6D6D6;
}

.newOptionsRow .mat-mdc-checkbox {
  margin-right: 15px;
  margin-bottom: 10px;
}

.formSections{
  position: relative;
}

.formSections .row{
  margin:0;
}

.flightBorder{
  border-left:4px solid #4598D6;
  position: absolute;
  left:0;
  top:0;
  bottom:0;
}

.sectionBg{
  background-color: #EDF1FE;
  margin-bottom: 8px;
  padding-left: 10px;
  position: relative;
  padding-top:10px;
}

.paxCounter{
  position:relative;
  text-align: center;
}
.fakeFormLabel{
  font-size:10px;
  color: rgba(0, 0, 0, 0.54);
  position: relative;
  top:-5px;
  left:0;
  text-align: left;
}

.fakeQtyButton{
  display: inline-block;
  font-size:16px;
  vertical-align: middle;
  width:10%;
}

.fakeQtyInput{
  padding:13px 10px 14px 10px;
  display: inline-block;
  vertical-align: middle;
  width:80%;
  position: relative;
}

.fakeQtyInput:after{
  content: '';
  position: absolute;
  bottom:0;
  left:0;
  width: 100%;
  border-bottom:.8px solid #262626;
}

.fakeFormInput{
  margin-top:-5px;
}
.labelBtn{
  width:60%;
  display:inline-block;
}

.buttonMenu{
  width:40%;
  display:inline-block;
  text-align:right;

  button{
    border:none;
    background-color:#4598D6;
    color:#fff;
    border-radius: 50%;
    outline: none;
    height:25px;
    width:25px;
    line-height: 22px;
    margin-right:5px;
    text-align:center;
    .material-icons{
      font-size:14px;
    }
  }
}

.paxCount{
  text-align:center;
  display:inline-block;
  margin-right:5px;
}

.searchMenuItem{
  padding: 5px 10px!important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.54)!important;
  height: auto!important;
  display: block!important;
}

.cmDivider{
  border-bottom:1px solid #ddd;
}

.uiFare{
  position:relative;
  padding-top:5px;
  border:2px solid #D6D6D6; // #F5F5F5;
  margin: 0 0 10px;
  .fareNameNew{
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: bold;
  }
  .dialogFareNew{
    border:none;
    margin:0;
    .farePrice{
      margin:0;
      background-color: #F5F5F5;
      border:none;
      border-radius: 0;
      padding: 5px 10px;
    }
    .greytxt{
      color: #E05B59!important;
      font-size: 13px!important;
    }
    .bagInfo{
      font-size: 14px!important;
      color: #000!important;
    }
  }
  .absoluteCheck{
    position:absolute;
    top:5px;
    right: 5px;
    color:#8C8C8C;
  }
}

.uiFare.selectedRate{
  border:2px solid #EB5281;
  .dialogFareNew{
    .farePrice{
      background-color: #EB5281;
      color:#fff;
    }
  }
  .absoluteCheck{
    color:#EB5281;
  }
}

.pnrlocatorNew, .timeLimitNew{
  font-size:12px;
  font-weight: 600;
}

.pnrNewStatus{
  padding: 4px 8px 4px 8px;
  text-align:center;
  border-radius: 32px;
  display: inline-block;
  font-size:12px;
  .material-icons{
    font-size:18px;
  }
}

.linkedNew{
  border: 0.8px solid #D9E7DF;
  background: #F5FBF5;
  .material-icons{
    color:#479D5E;
  }
}

.expiredNew, .cancelledNew{
  border: 0.8px solid #F7E2E5;
  background: #FFFAFA;
  .material-icons{
    color:#E05B59;
  }
}

.initNew{
  border: 0.8px solid #FCEBA2;
  background: #FEFAE8;
  .material-icons{
    color:#F7D22D;
  }
}

.issuedNew{
  border: 0.8px solid #479D5E;
  background: #FFF;
  .material-icons{
    color:#479D5E;
  }
}

.timeLimit{
  border: 0.8px solid #FCEBA2;
  background: #FEFAE8;
  .material-icons{
    color:#F7D22D;
  }
}

.counterlimitNew{
  color: #E05B59;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}

.newPnrPagination{
  padding-top:20px;
  white-space: nowrap;
  overflow-x:auto;
}

.singleNewVoice{
  display: inline-block;
  padding:10px 20px;
  border-bottom:2px solid transparent;
  cursor:pointer;
  font-size:12px;
  font-weight: normal;
}

.activeNew{
  color:#EB5281;
  border-bottom:2px solid #EB5281;
}

.newTabHeader{
  border-top: 1px solid #D6DBE1;
  border-right: 1px solid #D6DBE1;
  border-left: 1px solid #D6DBE1;
  border-bottom: 2px solid #D6DBE1;
  background: #FFF;
  padding:15px 20px;
  font-weight: 500;
}

.padding1020{
  padding:15px 20px;
}

#tabsContainerNew{
  background: #FFF;
  border-right: 1px solid #D6DBE1;
  border-left: 1px solid #D6DBE1;
  border-bottom: 2px solid #D6DBE1;
}

.newTabButtons{
  .mat-mdc-icon-button, .mat-mdc-button-touch-target{
    height:30px!important;
    width:30px!important;
    .mat-icon{
      height: 18px!important;
      width: 18px!important;
      font-size: 18px!important;
      display: flex!important;
      align-self: center!important;
    }
  }
}
.alert, .isVenice, .alert-danger{
  font-size: 14px!important;
  a{
    font-size: 14px!important;
    padding-left: 2.5px!important;
    color: #000;
    text-decoration: underline;
  }
}
.paginationr{
  display: flex;
  align-items: center;
  .selectPage {
    width: 60px!important;
    .mat-mdc-form-field-subscript-wrapper{
      display : none;
    }
  }
}
.bold{
  font-weight: bold;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled){
  background-color: transparent !important;
}
.mat-mdc-form-field-focus-overlay {
  background-color: transparent!important;
}
.mat-mdc-tab,.mdc-tab{
  min-width: 120px!important;
  --mat-tab-animation-duration: 400ms;
  min-height: 54px!important;
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: .3;
  background-color: transparent;
}
.iconButtons, .mat-mdc-button-touch-target, .mat-mdc-icon-button .mat-mdc-focus-indicator{
  width: 40px!important;
  height: 40px!important;
  padding: 8px!important;
  .mat-icon{
    display: flex!important;
    align-self: center!important;
    font-size: 24px!important;
  }
  img{
    display: flex;
    align-self: center;
  }
  .mat-badge-medium,.mat-badge-overlap,.mat-badge-after,.mat-badge-content{
    top: -5px!important;
    right: -3px!important;
    font-size: 10px!important;
    font-weight: bold!important;
  }
}
.topbarbadges{
  .mat-badge-medium,.mat-badge-overlap,.mat-badge-after,.mat-badge-content{
    top: -3px!important;
    right: -3px!important;
    font-size: 10px!important;
    font-weight: bold!important;
  }
}
.listButton{
  height: 40px!important;
  max-height: 40px!important;
  font-size: 12px!important;
}
.uploadIcon, .mat-mdc-mini-fab,.mat-mdc-button-touch-target{
  height: 30px!important;
  width: 30px!important;
  .mat-icon{
    top: 5px;
    margin: 6px!important;
    font-size: 18px!important;
    height: 18px!important;
    display: flex;
    align-self: center!important;
  }
}
.mat-mdc-menu-item-text{
  width: 100%;
}
label{
  margin-bottom: initial;
}
.mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-bottom-align::before{
  font-size:11px;
}
.minititle{
  color:#4d79f6;
}
.serviceImageCr{
  width: 30px;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 4px;
}
.iconTech{
  font-size:16px;
  color:#999;
  margin-right:4px;
  vertical-align: middle;
}

.mat-datepicker-content-container{
  background-color: #fff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-calendar-body-cell-content{
  border:none!important;
}
.paginators{
  background-color: #fff!important;
  margin: 0 0!important;
  padding: 0 0!important;
  box-shadow:0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  font-size:12px;
  color:rgba(0,0,0,.54);
}
.menuItem{
  .mat-mdc-menu-item-text{
    display: flex!important;
    align-items: center!important;
  }
}
.warn{
  max-width: 222px!important;
  padding: 0!important;
  .mat-mdc-menu-item-text{
    padding: 6px 20px!important;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
}
.profileMenu{
  .mat-mdc-menu-item{
    width: 100% !important;
  }
}
.companySubSpan{
  padding: 8px!important;
  width: 100px;
  height: auto;
  min-height: 30px!important;
  border-radius: 6px;
  font-size: 12px;
  .miniCross,.mdc-icon-button, .mat-mdc-icon-button, .mat-mdc-focus-indicator, .mat-mdc-button-touch-target, .mat-mdc-button-ripple{
    width: 16px!important;
    height: 16px!important;
    padding: 3px!important;
    .mat-icon{
      font-size: 10px!important;
      display: flex;
      align-self: center;
    }
  }
}
.miniIcons, .mat-mdc-button-touch-target, .mat-mdc-icon-button .mat-mdc-focus-indicator{
  width: 30px!important;
  height: 30px!important;
  padding: 6px!important;
  .mat-icon{
    display: flex!important;
    align-self: center!important;
    font-size: 18px!important;
  }
}
@import "@angular/cdk/overlay-prebuilt.css";
.ks-modal-gallery-backdrop {
  background: #000 !important;;
  opacity: 0.85 !important;;
}
.ks-modal-gallery-panel {
  z-index: 90000 !important;
}
.gatPenalties{
  background-color: #fecc02!important;
  color:#000;
}

.ndcBanner{
  display:inline-block;
  padding:3px 5px;
  border-radius: 5px;
  font-size:10px;
  color:#fff;
  background-color: #EB5281;
}
.flyPenalties{
  background-color: #0288d1 !important;
  color: #fff;
}
.buttonrowBis button, .buttonrowBis a {
  margin: 10px 5px 5px 0;
  height: 24px;
  line-height: 22px;
  font-size: 11px;
  padding: 1px 5px;
}

.buttonrowBis .material-icons{
  font-size:14px;
  vertical-align:middle;
  margin-right:4px;
}

.policiesList .mdc-data-table__row:hover{
  background-color: rgba(241, 241, 241, 0.4) !important;
}
